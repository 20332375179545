import React, { useState } from 'react';
import { UseStateContext } from '../context/StateContext';
import { BiMenuAltRight } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { isTrue } from '../functions';
import { parseCookies } from 'nookies';

const Nav = () => {
  const location = useLocation();
  const cookies = parseCookies();
  const { user, signOut, setOpenMenu } = UseStateContext();
  const router = useHistory();
  const [openP, setOpenP] = useState(false);
  const admin = location.pathname.includes('/admin');
  const matching = location.pathname.includes('/matching');

  const gotowing288 = () => {
    window.open('https://m.wing288.me/?fid=WINGTYAA');
  };
  const closeP = () => {
    setTimeout(() => {
      setOpenP(false);
    }, 200);
  };
  return (
    <div
      className={`bg-[#0F172A] px-1 sticky top-0 z-20 ${
        matching && 'hidden md:block'
      }`}
    >
      <div
        className={`mx-auto max-w-${
          admin ? 'screen' : '[1300px]'
        } flex items-center`}
      >
        <div className="w-5/12 flex">
          <div
            className="flex items-center mx-1 py-2"
            onClick={() => {
              setOpenMenu(false);
              router.push('/');
            }}
          >
            <p className="ml-1 font-bold text-base sm:text-xl cursor-pointer text-white ">
              WING288-FT<span className="text-rose-600">.</span>
            </p>
          </div>
        </div>
        <div className="w-2/12 flex flex-col justify-center items-center">
          <div>
            <img
              className="cursor-pointer hover:opacity-50 py-1"
              onClick={() => gotowing288()}
              style={{ minWidth: '50px', maxWidth: '50px' }}
              src="/banner/wing288logo.png"
              alt=""
            />
          </div>
        </div>
        <div className="w-5/12 flex justify-end">
          <div className="text-white hidden lg:flex justify-center items-center konFont font-bold text-base">
            <button
              onClick={() => router.push('/football')}
              type="button"
              className="mx-2 hover:text-white"
            >
              ⚽កីឡាបាល់ទាត់
            </button>
            {/* <button
              className="mx-2 hover:text-white"
              onClick={() => router.push('/boxing')}
            >
              ប្រដាល់
            </button> */}
            <button
              className="mx-3 hover:text-white"
              onClick={() => router.push('/news')}
            >
              📰ព័ត៌មាន
            </button>
            {/* <button
              className="mx-2 hover:text-white"
              onClick={() => window.open('https://www.drama855.com')}
            >
              🎬ភាពយន្ដ
            </button> */}
          </div>
          <div className="lg:hidden block">
            <BiMenuAltRight
              onClick={() =>
                setOpenMenu((o) => {
                  return !o;
                })
              }
              size={35}
              className="text-white hover:text-[#00AAFF] cursor-pointer mr-2"
            />
          </div>
          <div className="lg:block hidden items-center justify-center">
            {user && (
              <>
                <button
                  className="mx-1 text-white khFont mt-2 relative"
                  onClick={() => setOpenP(true)}
                  onBlur={closeP}
                >
                  <img
                    className="w-6 h-6 mx-1 border-2 border-[#00AAFF] rounded-full"
                    src="/user2.png"
                    alt=""
                  />
                  <div
                    className={`${
                      openP ? '' : 'hidden'
                    } cursor-default p-2 w-[230px] absolute right-2 top-[40px] bg-[#1E293B] border border-gray-600 rounded-lg`}
                  >
                    <p className="text-[#00AAFF] font-bold mb-2">ការកំណត់</p>
                    {isTrue(cookies, 'Admin') && (
                      <button
                        className="w-full p-1 rounded-lg hover:font-bold"
                        onClick={() => router.push('/admin/news')}
                      >
                        អែតមីន
                      </button>
                    )}

                    <button
                      className="w-full p-1 rounded-lg text-rose-800 font-bold hover:text-rose-600"
                      onClick={async () => {
                        signOut();
                        closeP();
                        await router.push('/auth/login');
                      }}
                    >
                      ចេញពីគណនី
                    </button>
                  </div>
                </button>
              </>
            )}
            {!user && (
              <>
                <button
                  className="mx-1 hover:bg-sky-600 bg-[#00AAFF] px-3 py-1 rounded-lg khFont font-bold"
                  onClick={() => router.push('/auth/login')}
                >
                  ចូលគណនី
                </button>
                <button
                  className=" bg-rose-600 hover:bg-rose-500 px-3 py-1 rounded-lg khFont font-bold"
                  onClick={() => router.push('/auth/register')}
                >
                  បង្កើតគណនី
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
