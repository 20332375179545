import React, { useEffect, useState } from 'react';
import MatchCom from '../components/MatchCom';
import { useHistory } from 'react-router-dom';
import GraphQL_API, {
  LeagueGQL,
  MatchGQL,
  NewsGQL,
  ScheduleGQL,
} from '../graphql';
import Slider from '../components/Slider';
import TextScroller from '../components/TextScroller';
import Moment from 'react-moment';
import SideBanner from '../components/SideBanner';
import { AiFillSchedule } from 'react-icons/ai';
import LeagueStand from '../components/LeagueStand';
import MatchComS from '../components/MatchComS';
// import { UseTimeContext } from '../context/TimeContext';

const Dashboard = () => {
  const router = useHistory();
  const client = GraphQL_API({ ctx: null });
  const [matches, setMatches] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [newss, setNewss] = useState([]);
  const [leagueId, setLeagueId] = useState('6');
  const [season, setSeason] = useState(null);
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [schedulesF, setSchedulesF] = useState([]);
  const [schedulesTomorrow, setSchedulesTommorrow] = useState([]);
  const [dateS, setDateS] = useState({ today: '', tomorrow: '' });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchLeagueStanding(leagueId);
  }, [leagueId]);

  const fetchLeagueStanding = async (lid) => {
    const { data: seasons } = await client.query({
      query: LeagueGQL.GET_LEAGUE_STANDING_BY_LEAGUE_ID,
      variables: {
        filters: {
          and: [
            {
              league: {
                id: {
                  eq: lid,
                },
              },
            },
            {
              season: {
                eq: '23-24',
              },
            },
          ],
        },
        pagination: {
          limit: 20,
        },
      },
    });

    // console.log(errors?.graphQLErrors[0]?.extensions?.error);

    if (seasons && seasons.leagueStandings.data[0]) {
      setSeason(seasons.leagueStandings.data[0]);
    } else if (seasons && !seasons.leagueStandings.data[0]) {
      setSeason(null);
    }
  };

  const fetchData = async () => {
    const curr = new Date();
    const live = curr.getTime() - 1000 * 60 * 60 * 2;
    const gt = new Date(live);
    // const end = new Date(curr.getTime() + 1000 * 60 * 60 * 24 * 1);

    const today = `${curr.getFullYear()}-${
      curr.getMonth() + 1
    }-${curr.getDate()}`;
    const tomorrow = `${curr.getFullYear()}-${curr.getMonth() + 1}-${
      curr.getDate() + 1
    }`;

    setDateS({ today: today, tomorrow: tomorrow });

    const { data: s } = await client.query({
      query: ScheduleGQL.GET_SCHEDULES,
      variables: {
        filters: {
          schedule: {
            in: [today],
          },
        },
        sort: ['schedule:asc'],
        sortM: ['league.createdAt:asc', 'match_date:asc'],
      },
    });
    if (s) {
      setSchedules(s.schedules.data);
      console.log(s.schedules.data);
    }

    const { data: sT } = await client.query({
      query: ScheduleGQL.GET_SCHEDULES,
      variables: {
        filters: {
          or: {
            schedule: {
              in: [tomorrow],
            },
          },
        },
        sort: ['schedule:asc'],
        sortM: ['league.createdAt:asc', 'match_date:asc'],
      },
    });
    if (sT) {
      setSchedulesTommorrow(sT.schedules.data);
    }

    const { data: sF } = await client.query({
      query: ScheduleGQL.GET_SCHEDULES,
      variables: {
        filters: {
          schedule: {
            lt: today,
          },
        },
        sort: ['schedule:desc'],
        sortM: ['league.createdAt:asc', 'match_date:asc'],
      },
    });
    if (sF) {
      setSchedulesF(sF.schedules.data);
    }

    const { data } = await client.query({
      query: LeagueGQL.GET_LEAGUES,
      variables: {
        filters: {
          show: {
            eq: true,
          },
        },
        pagination: {
          pageSize: 100,
        },
      },
    });

    if (data) {
      setLeagues(data.leagues.data);
    }
    // matches
    const { data: matches } = await client.query({
      query: MatchGQL.GET_MATCHES,
      variables: {
        filters: {
          or: [
            {
              schedule: {
                schedule: {
                  eq: today,
                },
              },
            },
            {
              match_date: {
                lt: curr,
                gt: gt,
              },
            },
          ],
        },
        sort: ['league.createdAt:asc', 'match_date:asc'],
        pagination: {
          pageSize: 100,
        },
      },
    });

    if (matches) {
      setMatches(matches.matches.data);
      console.log(matches.matches.data);
    }
  };

  return (
    <div className="mx-auto max-w-[1300px]">
      <Slider />
      <TextScroller />
      <div className="flex flex-col md:flex-row mt-3">
        <div className="w-full md:min-w-[280px] md:max-w-[280px] text-white hidden xl:block order-0">
          <SideBanner />
        </div>
        <div className="w-full order-0 md:order-1 overflow-hidden mx-0 sm:mx-1">
          <div className="rounded-md overflow-x-auto w22Scroll mb-2 pb-1 ">
            <div className="min-w-[1500px] max-w-[1500px]">
              <div className="flex">
                <div
                  className={`flex items-center select-none cursor-pointer rounded-md pl-1 pr-4 mb-1 mr-1 drop-shadow ${
                    'Match' === leagueId
                      ? 'bg-white text-gray-900'
                      : 'bg-[#00AAFF] hover:bg-sky-600 text-gray-900'
                  }`}
                  onClick={() => {
                    setLeagueId('Match');
                  }}
                >
                  <AiFillSchedule
                    size={23}
                    className={`${'Match' === leagueId ? 'text-rose-600' : ''}`}
                  />
                  <p className="ml-1 font-bold text-sm">ការប្រកួត</p>
                </div>
                {leagues.map((league) => (
                  <div
                    key={league.id}
                    className={`flex items-center select-none cursor-pointer rounded p-[2px] pl-2 pr-4 mb-1 mr-1 drop-shadow ${
                      league.id === leagueId
                        ? 'bg-white text-gray-900'
                        : 'bg-[#00AAFF] hover:bg-sky-600 text-gray-900'
                    }`}
                    onClick={() => {
                      setLeagueId(league.id);
                    }}
                  >
                    <img
                      width={25}
                      height={25}
                      src={league.attributes.league_logo}
                      alt=""
                    />
                    <p className="ml-2 font-bold text-sm">
                      {league.attributes.league_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {matches.length === 0 ? (
            ''
          ) : (
            <div
              className={`flex items-center select-none cursor-pointer rounded-md pl-1 pr-4 mb-1 mr-1 drop-shadow text-rose-600`}
            >
              <AiFillSchedule size={23} />
              <p className="ml-1 font-bold text-sm">ការប្រកួត</p>
            </div>
          )}

          <MatchComS
            schedules={
              matches.length !== 0
                ? [
                    {
                      id: 'sd',
                      attributes: {
                        schedule: dateS.today,
                        matches: {
                          data: matches,
                        },
                      },
                    },
                  ]
                : []
            }
            dateS={dateS}
          />
          <MatchComS schedules={schedulesTomorrow} dateS={dateS} />
          <MatchComS schedules={schedulesF} dateS={dateS} />

          {/* {matches.map((m, i) => {
            const league = m.attributes.league.data;
            return (
              <div
                key={i + 'league'}
                className=" mb-2 rounded-md overflow-hidden"
              >
                <div className="flex justify-between">
                  <div className="flex bg-gradient-to-r from-[#0C304F] justify-between w-full">
                    <div className="flex items-center p-1 px-2">
                      <img
                        className="h-4 cursor-pointer"
                        src={league.attributes.league_logo}
                        alt=""
                        onClick={() => router.push(`/leagues/${league.id}`)}
                      />
                      <span
                        onClick={() => router.push(`/leagues/${league.id}`)}
                        className="ml-2 font-[600] text-sm text-rose-600 hover:underline cursor-pointer"
                      >
                        {league.attributes.league_name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div key={m.id} className="w-full">
                    <MatchCom m={m} />
                  </div>
                </div>
              </div>
            );
          })} */}

          {loadingSeason ? (
            <>
              <p className="text-white font-bold">Loading...</p>
            </>
          ) : (
            season && (
              <div className="mb-4 text-gray-300 ">
                <div
                  className="font-bold text-rose-600 flex hover:underline cursor-pointer"
                  onClick={() => router.push(`/leagues/${leagueId}`)}
                >
                  <img
                    width={25}
                    height={25}
                    src={season.attributes.league.data.attributes.league_logo}
                    alt=""
                  />
                  <p className="pl-2">
                    {season.attributes.league.data.attributes.league_name}
                  </p>
                </div>
                <LeagueStand season={season} />
              </div>
            )
          )}

          <div className="">
            {newss.length !== 0 && (
              <div className="rounded-md">
                <div className="bg-[#1E293B] border border-gray-600 flex flex-col md:flex-row lg:flex-col xl:flex-row py-1 rounded">
                  <div
                    onClick={() => router.push(`/news/${newss[0].id}`)}
                    className="px-1 mb-1 w-full md:w-7/12 lg:w-full xl:w-7/12 relative cursor-pointer text-black overflow-hidden hover:text-rose-700 hover:underline"
                  >
                    <img
                      className="h-full w-full object-cover rounded-md"
                      src={newss[0].attributes.thumnail}
                      alt=""
                    />
                    <div
                      className="absolute bottom-0 left-0 w-full p-2 text-center"
                      style={{ background: 'rgba(206, 210, 216,0.7)' }}
                    >
                      <p className="font-[600] mx-line-2">
                        {newss[0].attributes.title}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-col justify-between md:w-5/12 lg:w-full xl:w-5/12 px-1">
                    {newss.map((n, i) => {
                      if (i === 0) return null;
                      return (
                        <div
                          onClick={() => router.push(`/news/${n.id}`)}
                          key={n.id}
                          className="flex cursor-pointer mb-1  text-gray-300 hover:text-rose-700 hover:underline"
                        >
                          <img
                            className="w-28 md:w-24 lg:w-28 h-20 md:h-20 mr-2 object-cover rounded"
                            src={n.attributes.thumnail}
                            alt=""
                          />
                          <div className="flex flex-col justify-between">
                            <div className="font-[600] mx-line-2 mt-1">
                              {n.attributes.title}
                            </div>
                            <p className="text-rose-600 text-xs">
                              <Moment format="MMM DD, YYYY">
                                {new Date(n.attributes.createdAt)}
                              </Moment>{' '}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-center">
                  <p
                    onClick={() => router.push(`/news`)}
                    className="cursor-pointer font-bold text-[#00AAFF] hover:text-white p-2 text-center mb-2 inline-block mx-auto"
                  >
                    មានទៀត
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-1 md:min-w-[280px] md:max-w-[280px] text-white lg:block order-2">
          <SideBanner />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
