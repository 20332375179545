import { gql } from '@apollo/client';

export const GET_SCHEDULES = gql`
  query schedules(
    $filters: ScheduleFiltersInput
    $pagination: PaginationArg
    $sort: [String]
    $filtersM: MatchFiltersInput
    $paginationM: PaginationArg
    $sortM: [String]
  ) {
    schedules(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          schedule
          matches(filters: $filtersM, pagination: $paginationM, sort: $sortM) {
            data {
              id
              attributes {
                analysis
                result {
                  id
                  home_gold
                  away_gold
                  won
                }
                country {
                  data {
                    id
                    attributes {
                      country_name
                      country_logo
                    }
                  }
                }
                league {
                  data {
                    id
                    attributes {
                      league_name
                      league_logo
                    }
                  }
                }
                home_team {
                  data {
                    id
                    attributes {
                      team_name
                      team_badge
                    }
                  }
                }
                away_team {
                  data {
                    id
                    attributes {
                      team_name
                      team_badge
                    }
                  }
                }
                match_date
                link {
                  id
                  link_name
                  link_type
                  link_url
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;
