import React from 'react';
import { useHistory } from 'react-router-dom';
import { UseTimeContext } from '../context/TimeContext';
import Moment from 'react-moment';
import { TbListDetails } from 'react-icons/tb';
import PopupDetails from './PopupDetails';

const MatchComS = ({ schedules, dateS }) => {
  const { currentTime } = UseTimeContext();
  const router = useHistory();
  const onClickLeague = (id) => {
    router.push(`/leagues/${id}`);
  };
  return (
    <div className="relative">
      {schedules.map((schedule, i) => {
        const s = schedule.attributes;
        const matchesFromS = schedule.attributes.matches.data;
        return (
          <React.Fragment key={`schedules${schedule.id}`}>
            <div className="overflow-hidden  border border-gray-600 rounded-md mb-1">
              <div className="flex justify-between bg-[#1E293B] w-full p-2">
                {s.schedule === dateS.today ? (
                  <p className="text-sm font-bold text-rose-600">ថ្ងៃនេះ</p>
                ) : s.schedule === dateS.tomorrow ? (
                  <p className="text-sm font-bold text-rose-600">ថ្ងៃស្អែក</p>
                ) : (
                  <></>
                )}
                <p className="text-blue-600 font-bold text-sm">{s.schedule}</p>
              </div>
              <div className="py-2">
                {matchesFromS.map((match, j) => {
                  const m = match.attributes;
                  return (
                    <React.Fragment key={`matchFromS${match.id}`}>
                      {j === 0 && (
                        <div className="flex w-full items-center">
                          <div className="w-[80px] flex justify-center">
                            <img
                              onClick={() => onClickLeague(m.league.data.id)}
                              className="w-[35px] h-[35px] cursor-pointer"
                              src={m.league.data.attributes.league_logo}
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col">
                            <span className="text-xs text-gray-500">
                              {m.country.data.attributes.country_name}
                            </span>
                            <span
                              className="text-xs text-gray-500 font-bold cursor-pointer select-none hover:underline"
                              onClick={() => onClickLeague(m.league.data.id)}
                            >
                              {m.league.data.attributes.league_name}
                            </span>
                          </div>
                        </div>
                      )}
                      {j !== 0 &&
                        m.league.data.id !==
                          matchesFromS[j - 1].attributes.league.data.id && (
                          <div className="flex items-center mt-2">
                            <div className="w-[80px] flex justify-center">
                              <img
                                onClick={() => onClickLeague(m.league.data.id)}
                                className="w-[35px] h-[35px] cursor-pointer"
                                src={m.league.data.attributes.league_logo}
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col">
                              <span className="text-xs text-gray-500">
                                {m.country.data.attributes.country_name}
                              </span>
                              <span
                                className="text-xs text-gray-500 font-bold cursor-pointer select-none hover:underline"
                                onClick={() => onClickLeague(m.league.data.id)}
                              >
                                {m.league.data.attributes.league_name}
                              </span>
                            </div>
                          </div>
                        )}

                      <div className="relative overflow-hidden">
                        <div className={`flex`}>
                          <div className="flex w-full">
                            <div className="w-[80px] flex justify-center items-center">
                              {m.result ? (
                                <p className="text-white font-bold pl-3 khFont text-xs">
                                  FT
                                </p>
                              ) : (
                                <>
                                  {currentTime >
                                    new Date(m.match_date).getTime() &&
                                  currentTime <
                                    new Date(m.match_date).getTime() +
                                      1000 * 60 * 60 * 2 &&
                                  m.link.length !== 0 ? (
                                    <div>
                                      <div className="relative text-xs pb-0">
                                        <span className="flex h-2 w-2 absolute top-[4px]">
                                          <span className="animate-ping absolute h-2 w-2 rounded-full bg-rose-600 "></span>
                                          <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-600 opacity-75"></span>
                                        </span>
                                        <p className="text-white font-bold pl-3 khFont text-xs">
                                          LIVE
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <p className="flex flex-col justify-center items-center text-gray-400 text-xs">
                                        <span className="">
                                          {new Date(m.match_date).getHours() ===
                                          0
                                            ? 12
                                            : new Date(
                                                m.match_date
                                              ).getHours() > 12
                                            ? new Date(
                                                m.match_date
                                              ).getHours() - 12
                                            : new Date(m.match_date).getHours()}
                                          :
                                          {new Date(m.match_date)
                                            .getMinutes()
                                            .toString().length > 1
                                            ? new Date(
                                                m.match_date
                                              ).getMinutes()
                                            : `0${new Date(
                                                m.match_date
                                              ).getMinutes()}`}
                                        </span>
                                        {new Date(m.match_date).getHours() < 12
                                          ? 'AM'
                                          : 'PM'}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div
                              className={`w-full my-1 border-l border-gray-700 pl-2 flex flex-col justify-center`}
                            >
                              <div className="flex mb-1">
                                <img
                                  className="w-[16px] h-[16px] mr-2"
                                  src={m.home_team.data.attributes.team_badge}
                                />
                                <p className="text-xs sm:text-sm text-gray-300 font-semibold">
                                  {m.home_team.data.attributes.team_name}
                                </p>
                              </div>
                              <div className="flex">
                                <img
                                  className="w-[16px] h-[16px] mr-2"
                                  src={m.away_team.data.attributes.team_badge}
                                />
                                <p className="text-xs sm:text-sm text-gray-300 font-semibold">
                                  {m.away_team.data.attributes.team_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-[30px] flex flex-col justify-center items-center">
                            <p
                              className={`mb-[2px] text-xs sm:text-sm font-semibold ${
                                m.result && m.result.won === 'home'
                                  ? 'text-gray-300'
                                  : 'text-gray-600'
                              }`}
                            >
                              {m.result && m.result.home_gold}
                            </p>
                            <p
                              className={`text-xs sm:text-sm font-semibold ${
                                m.result && m.result.won === 'away'
                                  ? 'text-gray-300'
                                  : 'text-gray-600'
                              }`}
                            >
                              {m.result && m.result.away_gold}
                            </p>
                          </div>
                        </div>
                        <div className="flex w-full">
                          <div className="w-[80px] flex justify-center items-center"></div>
                          <div className="w-full mb-4 flex items-center">
                            {m.link.map((link) => (
                              <React.Fragment key={`linkMfromS${link.id}`}>
                                <button
                                  title="ផ្សាយផ្ទាល់"
                                  className="shadow cursor-pointer mx-1 bg-rose-600 hover:bg-rose-500 text-xs px-1 text-black rounded-sm font-bold"
                                  // className="cursor-pointer bg-rose-600 hover:bg-rose-500 mx-1 text-xs px-1 text-white rounded-sm"
                                  onClick={() => {
                                    if (link.link_type === 'other_site') {
                                      window.open(link.link_url);
                                      return;
                                    }
                                    router.push(
                                      `/matching/${match.id}?linkId=${link.id}`
                                    );
                                  }}
                                >
                                  {link.link_type === 'Live'
                                    ? 'មើល'
                                    : link.link_name}
                                </button>
                              </React.Fragment>
                            ))}
                            <PopupDetails details={m.analysis || ''} />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MatchComS;
