import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

const TextScroller = () => {
  const [key, setKey] = useState(1);
  const [t, setT] = useState(0);

  useEffect(() => {
    let tMain = document.getElementById('t-main').offsetWidth;
    let tBody = document.getElementById('t-body').offsetWidth;
    setT((tMain / tBody) * 100 + 2);
    window.addEventListener('resize', () => {
      tMain = document.getElementById('t-main').offsetWidth;
      tBody = document.getElementById('t-body').offsetWidth;
      setT((tMain / tBody) * 100 + 2);
    });
  }, []);

  const scrolling = useSpring({
    from: { transform: `translate(${t}%,0)` },
    to: { transform: 'translate(-110%,0)' },
    config: { duration: 30000 },
    reset: true,
    // reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div className="p-1 mx-1 mb-1 bg-[#1E293B] rounded">
      <div id="t-main" className="w-full overflow-hidden" key={key}>
        <animated.div
          id="t-body"
          className="khFont text-ellipsis w-[1300px] font-[500] text-[#00AAFF] scroll-smooth"
          style={scrolling}
        >
          សូមស្វាគមន៍មកកាន់វេបសាយ www.wing288-ft.com
          គឺជាវេបសាយដំបូងគេនៅកម្ពុជាដែលប្រមូលផ្តុំទៅដោយព័ត៌មានកីឡាជាតិ-អន្តរជាតិ
          ការផ្សាយផ្ទាល់ព្រឹត្តិការណ៍វបាល់ទាត់ជុំវិញពិភពលោក
        </animated.div>
      </div>
    </div>
  );
};

export default TextScroller;
