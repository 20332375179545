import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import GraphQL_API, { ImgSliderGQL } from '../graphql';

const Slider = () => {
  const client = GraphQL_API({ ctx: null });

  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data: imgsFromServer } = await client.query({
      query: ImgSliderGQL.GET_IMG_SLIDER,
    });
    if (imgsFromServer) {
      setImgs(imgsFromServer.imgSlide.data.attributes.imgUrl);
    }
  };

  return (
    <div className="">
      <div className="drop-shadow rounded-lg overflow-hidden mb-2 cursor-pointer">
        <Carousel
          showArrows={true}
          // onChange={onChange}
          onClickItem={(e) => {
            window.open('https://m.wing288.me/?fid=WINGTYAA');
          }}
          // onClickThumb={onClickThumb}
          infiniteLoop={true}
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          emulateTouch={true}
          className="rounded-lg overflow-hidden"
        >
          {imgs.length !== 0 &&
            imgs.map((img) => (
              <div key={img.id} className="rounded-sm overflow-hidden">
                <img src={img.url} />
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Slider;
