import React, { useEffect, useState } from 'react';
import GraphQL_API, { MatchGQL } from '../graphql';
import { BsFillEyeFill } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
import Player from '../components/Player';
import Comment from '../components/Comment';
import { io } from 'socket.io-client';
import ReactPlayer from 'react-player';
import SideBanner from '../components/SideBanner';
// const socket = io(process.env.REACT_APP_COUNT_VIEW_API, {
//   reconnection: true,
//   //   reconnectionDelay: 1000,
//   //   reconnectionDelayMax: 5000,
//   reconnectionAttempts: 3,
//   transports: ['websocket'],
// });
// const socket = io('http://192.168.1.100:8101', {
//   reconnection: true,
//   //   reconnectionDelay: 1000,
//   //   reconnectionDelayMax: 5000,
//   reconnectionAttempts: 3,
//   transports: ['websocket'],
// });

let socket = null;

const Match = () => {
  const client = GraphQL_API({ ctx: null });
  const location = useLocation();
  const router = useHistory();
  const { mid } = useParams();
  const query = qs.parse(location.search);
  const linkId = query.linkId;
  const [currentLink, setCurrentLink] = useState();
  const [views, setViewer] = useState('');
  const [comments, setComments] = useState([]);
  const [match, setMatch] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!match) return;
    socket = io.connect(process.env.REACT_APP_COUNT_VIEW_API, {
      reconnect: true,
      transports: ['websocket', 'polling'],
    });
    socket.on('connect', function () {
      socket.emit('join-room', `football${match.id}`);
    });
    socket.on(`user-watching-football${match.id}`, (res) => {
      setViewer(res);
    });
    socket.on(`comment-room-football${match.id}`, (comment) => {
      addComment(comment.comment);
    });

    return () => {
      socket.disconnect();
    };
  }, [match]);

  const _createCommet = async (e) => {
    const comment = {
      comment: { ...e },
      room: `football${match.id}`,
    };
    socket.emit('comment', comment);
  };

  const addComment = async (comment) => {
    setComments((c) => {
      if (c.includes(comment)) return c;
      return [...c, comment];
    });
  };

  useEffect(() => {
    fetchData();
  }, [linkId]);

  const fetchData = async () => {
    const { data } = await client.query({
      query: MatchGQL.GET_MATCH_BY_ID,
      variables: { id: mid },
    });
    if (data) {
      setMatch(data.match?.data);
      setCurrentLink(
        data.match?.data.attributes.link.find((l) => l.id === linkId)
      );
    }
  };

  return (
    <>
      <div className="flex justify-around text-gray-100">
        <div className="hidden lg:block w-full max-w-[280px] xl:max-w-[300px] p-1">
          <div className="sticky top-[92px]">
            <SideBanner />
          </div>
        </div>
        <div className="w-full max-w-[600px] mt-3">
          <div className="bg-black min-h-[200px] relative">
            {currentLink?.link_type === 'embed' && (
              <>
                <Player url={currentLink?.link_url} />
                <div
                  className="absolute top-[10px] left-[100px] flex"
                  style={{ zIndex: 8 }}
                ></div>
              </>
            )}
            {currentLink?.link_type === 'm3u8' && (
              <>
                <div className="flex relative justify-center md:items-center">
                  <ReactPlayer
                    url={currentLink.link_url}
                    playing={true}
                    controls={true}
                    muted
                    width="100%"
                    height="auto"
                  />
                </div>
              </>
            )}
            <div className="flex absolute top-[10px] justify-center w-full">
              <div className="flex items-center" style={{ zIndex: 8 }}>
                <span className="bg-red-700 bg-opacity-90 font-bold text-white px-2 rounded text-sm mr-1">
                  LIVE
                </span>
                {views && (
                  <span
                    className="text-white px-2 rounded text-sm flex"
                    style={{ background: 'rgba(0,0,0,.5)' }}
                  >
                    <BsFillEyeFill className="mt-[3px] mr-1" />{' '}
                    <span className="mt-[1px] khFont font-bold">{views}</span>
                  </span>
                )}
              </div>
              <div>
                <IoCloseSharp
                  className="cursor-pointer hover:text-rose-500 text-rose-600 "
                  onClick={() => {
                    router.push('/');
                  }}
                  size={23}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="font-[600] text-center mt-2 text-[#00AAFF]">
              {currentLink?.link_name}
            </p>
            <div className="flex justify-center items-center p-1 py-2">
              <div className="w-full flex justify-end">
                <p className="truncate text-sm font-[600]">
                  {match?.attributes.home_team.data.attributes.team_name}
                </p>
                <img
                  className="w-6 h-6 mx-2"
                  src={match?.attributes.home_team.data.attributes.team_badge}
                  alt=""
                />
              </div>
              <div> - </div>
              <div className="w-full flex">
                <img
                  className="w-6 h-6 mx-2"
                  src={match?.attributes.away_team.data.attributes.team_badge}
                  alt=""
                />
                <p className="truncate text-sm font-[600]">
                  {match?.attributes.away_team.data.attributes.team_name}
                </p>
              </div>
            </div>
            <div className="px-2 flex flex-col items-center">
              <p className="text-center text-rose-600 font-[600]">
                ឧបត្ថម្ភដោយ
              </p>
              <img
                className="max-w-[200px]"
                onClick={() =>
                  window.open('https://m.wing288.me/?fid=WINGTYAA')
                }
                src="/banner/wing288logo.png"
                alt=""
              />
              <div className="font-[400] px-2 text-center">
                Wing288 ជាវេបសាយកម្សាន្ដអនឡាញដែលមានកីឡា
                💫ចុះឈ្មោះ-ដំបូងទទួលទឹកប្រាក់ 111$ ដុល្លារ និង ☆
                ទឹកប្រាក់បង្វិលជូន រហូតដល់ 2021$ ☆ បន្ថែម 28% លើការលេងបាការ៉ាត់
                និង កេណូ។ 📲 ទនាក់ទំនងចុះឈ្មោះតារយ: Inbox ☎️
                <span className="text-rose-600">087372288</span>,{' '}
                <span className="text-rose-600">012372288</span>,{' '}
                <span className="text-rose-600">090372288</span>{' '}
                <p
                  className="underline text-[#00AAFF] cursor-pointer hover:opacity-90"
                  onClick={() =>
                    window.open('https://m.wing288.me/?fid=WINGTYAA')
                  }
                >
                  👉លីងចុះឈ្មោះខ្លួនឯងជាមួយ Wing288
                </p>
              </div>
              <p></p>
            </div>
            <p className="text-sm p-1">Comments</p>
          </div>
          <div className="px-1 mb-2">
            {match && (
              <Comment
                HC={500}
                _createCommet={_createCommet}
                comments={comments}
                mid={`football${match.id}`}
                id={mid}
                sport={'football'}
                linkId={linkId}
                setComments={setComments}
              />
            )}
          </div>
        </div>
        <div className="hidden md:block w-full max-w-[280px] xl:max-w-[300px] p-1">
          <div className="sticky top-[92px]">
            <SideBanner />
          </div>
        </div>
      </div>
    </>
  );
};

export default Match;
