import { LockClosedIcon } from '@heroicons/react/20/solid';
import { setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UseStateContext } from '../context/StateContext';
import GraphQL_API, { UserGQL } from '../graphql';

const Login = () => {
  const router = useHistory();
  const client = GraphQL_API({ ctx: null });
  const { signIn } = UseStateContext();
  const location = useLocation();
  const redirect = location.search
    ? location.search.split('redirect=')[1]
    : '/';
  const [error, setError] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const _login = async (e) => {
    e.preventDefault();
    setError();
    const identifier = e.target.identifier.value;
    const password = e.target.password.value;
    if (password.length < 6) {
      setError({ password: 'បញ្ចូលពាក្យសម្ងាត់យ៉ាងតិច 6' });
      return;
    }
    try {
      const { data: loginUser } = await client.mutate({
        mutation: UserGQL.LOGIN,
        variables: {
          input: {
            identifier: identifier,
            password: password,
          },
        },
      });
      const { data: userData } = await client.query({
        query: UserGQL.GET_USER_BY_ID,
        variables: {
          id: loginUser.login.user.id,
        },
      });
      setCookie(
        null,
        'auth_ft',
        JSON.stringify({
          jwt: loginUser.login.jwt,
          user: userData.usersPermissionsUser.data,
        }),
        {
          maxAge: e.target.rememberMe.checked ? 30 * 24 * 60 * 60 : null,
          path: '/',
        }
      );
      signIn(userData.usersPermissionsUser.data);
      await router.push(redirect);
    } catch (error) {
      console.log(error?.graphQLErrors[0]?.extensions?.error);
      if (
        error?.graphQLErrors[0]?.extensions?.error.message ===
        'Invalid identifier or password'
      ) {
        setError({
          message: 'ឈ្មោះ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ ឬមិនទាន់បានចុះឈ្មោះនូវឡើយ',
        });
      }
    }
  };

  return (
    <>
      <div className="khFont flex min-h-[800px] justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-[320px] space-y-8">
          <div>
            <h2 className="mt-6 text-[#00AAFF] text-center text-3xl font-bold tracking-tight">
              ចូលគណនី
            </h2>
            <p className="text-center text-sm text-gray-600"></p>
          </div>

          <form className="space-y-4" onSubmit={_login}>
            {error && error.message && (
              <p className="text-center text-rose-600 text-sm">
                {error.message}
              </p>
            )}
            {error && error.password && (
              <p className="text-center text-rose-600 text-sm">
                {error.password}
              </p>
            )}
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="identifier"
                  type="text"
                  required
                  autoComplete="off"
                  className="bg-[#1E293B] text-[#00AAFF] placeholder-gray-500 font-bold relative block w-full appearance-none rounded-none rounded-t-md border border-gray-600 px-3 py-2 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="ឈ្មោះ"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  ពាក្យសម្ងាត់
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="bg-[#1E293B] text-[#00AAFF] font-bold relative block w-full appearance-none rounded-none rounded-b-md border border-gray-600 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="ពាក្យសម្ងាត់"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="rememberMe"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-600 font-bold cursor-pointer"
                >
                  រក្សាទុក ៣០ថ្ងៃ
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                ចូលគណនី
              </button>
              <div className="flex justify-between mt-2 text-gray-500">
                <p>មិនទាន់មានគណនី?</p>
                <p
                  className="cursor-pointer underline text-base text-rose-600 font-[600]"
                  onClick={() =>
                    router.push(`/auth/register?redirect=${redirect}`)
                  }
                >
                  ចុះឈ្មោះទីនេះ
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
